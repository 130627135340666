import React, { useEffect } from "react";
import Banner from "./home/Banner";
import NewsReleases from "./home/news_releases";
import OurPartners from "./home/our_partners";
import OurPortfolio from "./home/our_portfolio";
import OurSolution from "./home/our_solution";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: 70 }}>
      <div className="container-wrapper">
        <Banner />
        <OurPortfolio />
        <OurSolution />
        <OurPartners />
        {/* <NewsReleases /> */}
      </div>
    </div>
  );
};

export default Home;
